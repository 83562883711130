import React from 'react';

import { Layout, SEO } from 'src/components/shared/';

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<h1>NOT FOUND</h1>
	</Layout>
);

export default NotFoundPage;
